$(document).ready(function() {
    $('.website').mousedown(function(event){
        if (event.which == 3) {return;}
        let link = $(this).find('a');
        let target = (!link.attr('target') ? "_self" : link.attr('target'));
        if (event.which != 1 || event.ctrlKey) {
            target = "_blank";
        }
        window.open(link.attr('href'), target);
    });
});